<script>
// import VueSelect from "vue-select"

import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import WidgetView from "@/components/WidgetView.vue";
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import { vMaska } from "maska"

export default {
  setup: () => ({v$: useVuelidate()}),
  components: {
    WidgetView
    // VueSelect
  },
  directives: { maska: vMaska },
  data() {
    return {
      value: [],
      name: this.fields.fields[6].value,
      phone: this.fields.fields[10].value,
      pvz: {},
      policy_checkbox: true
    };
  },
  validations() {
    return {
      name: {required},
      phone: {required},
    }
  },
  props: ['token','fields'],
  methods: {
    sendForm() {
      this.v$.$validate()
      if (!this.v$.$error && localStorage.getItem('pvz') !== null) {
        let domain = this.$route.query.env === 'dev' ? 'https://dev.platform-easycode.ru' : 'https://platform-easycode.ru'
        axios.post(domain + '/api/delivery/saveFields', {
          token: this.token,
          values: [
            {
              field_id: 1,
              value: this.name
            },
            {
              field_id: 2,
              value: this.phone
            },
          ],
          pvz: localStorage.getItem('pvz')
        }).then((res) => {
          console.log('Данные отправились', res)
          this.$emit('finish')
          this.$emit('finish_banner', true)
        }).catch(err => {
          console.log(err.response)
          toast("Что то пошло не так, обратитесь к менеджеру за помощью", {
            "theme": "colored",
            "type": "error",
            "position": "bottom-right",
            "dangerouslyHTMLString": true
          });
        })
      } else {
        if (localStorage.getItem('pvz') !== null) {
          toast("Данные не все заполнены", {
            "theme": "colored",
            "type": "error",
            "position": "bottom-right",
            "dangerouslyHTMLString": true
          }); // ToastOptions
        }
        if (localStorage.getItem('pvz') === null) {
          toast("Не выбран пункт выдачи заказов", {
            "theme": "colored",
            "type": "error",
            "position": "bottom-right",
            "dangerouslyHTMLString": true
          }); // ToastOptions
        }
      }
    }
  },
  mounted() {

  }
}
</script>

<template>
  <div>
    <div>
      <input class="sdek_delivery__input" :style="v$.name.$error ? 'border-color: #7d0d0d42':false" v-model="name"
             placeholder="ФИО">
    </div>
    <div v-if="v$.name.$error" class="validate_style_text">Заполните имя</div>
      <input class="sdek_delivery__input" :style="v$.name.$error ? 'border-color: #7d0d0d42':false" v-maska data-maska="+7 (###) ###-##-##" v-model="phone"
             placeholder="Номер телефона">
    <div v-if="v$.name.$error" class="validate_style_text">Заполните номер</div>
    <div class="row sdek_delivery__content">
      <div class="col-6 sdek_delivery__title">
        Доставка СДЭК
      </div>
      <div class="col-6 text-end sdek_delivery__not">
        <span @click="this.$emit('type','pochta_delivery')" class="sdek_delivery__text cursor-pointer">
          В моём городе нет СДЭК
        </span>
      </div>
    </div>
    <div>
      <!--      <div>-->
      <!--        <vue-select :options="options" class="vue_select__style" placeholder="Город / село" label="name"/>-->
      <!--      </div>-->
      <div class="delivery_widget">
        <widget-view @deliveryInfo="pvz = $event"></widget-view>
      </div>
      <div class="policy__content">
        <span class="form-check">
          <input class="form-check-input" type="checkbox" v-model="policy_checkbox">
        </span>
        <span>
          <a class="policy__content__text">
          Я согласен с
          </a>
        </span>
        <span>
         <a href="https://easycode.tech/oferta2" target="_blank" class="policy__content__link">
           политикой обработки персональных данных
         </a>
        </span>
      </div>
    </div>
    <div class="row sdek__btn">
      <div class="col-6 text-center problem__btn__position">
        <a href="http://wa.me/79950950560?text=Здравствуйте,%20у%20меня%20вопрос%20по%20полям%20доставки" target="_blank" class="btn problem__btn">У меня проблема</a>
      </div>
      <div class="col-6 text-center problem__btn__position">
        <button @click="sendForm" :class="policy_checkbox === false ? 'disabled' : false" class="btn btn-primary send__btn">Отправить</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sdek_delivery__input {
  height: 52px;
  padding: 30px 20px 12px 20px;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  border: none;
  outline: none;
  width: 100%;

  border-bottom: 1px solid #CAD9FF
}

.sdek_delivery__content {
  margin-top: 40px;
}

.sdek_delivery__title {
  color: #24292D;

  font-family: 'Montserrat-Bold';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sdek_delivery__text {
  color: #134DE2;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #134DE2;
}

.mt-36 {
  margin-top: 36px;
}

.vue_select__style {
  color: rgba(36, 41, 45, 0.60);
  font-size: 16px;
}

.policy__content {
  margin-top: 30px;

  display: flex;
  align-items: center;
}

.policy__content__text {
  color: #24292D;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  text-decoration: none;
}

.policy__content__link {
  opacity: 0.8;
  color: #134DE2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  border-bottom: 1px solid #134DE2;

  margin-left: 3px;
}

.sdek__btn {
  margin-top: 15px;
}

.problem__btn {
  display: flex;
  padding: 16px 60px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: 16px;
  border: 1px solid #CAD9FF;

  color: #134DE2;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
}

.validate_style_text {
  color: rgba(125, 13, 13, 0.62);
  padding-left: 10px;
}
</style>